import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Web3ReactManager from 'components/Web3ReactManager';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'semantic-ui-css/semantic.min.css';

import configureStore, { history } from './redux/configureStore';
import sagas from './redux/sagas';
import App from './App';
import './index.less';
import reportWebVitals from './reportWebVitals';

// Initialize store
const store = configureStore();
sagas.forEach((saga) => store.runSaga(saga));

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 1500;
  return library;
}

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ReactManager>
        <App history={history} />
        <NotificationContainer />
      </Web3ReactManager>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
