import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'semantic-ui-react';

import icons from 'assets/icons';
import { BearDetails } from 'components';
import { shortenAddress } from 'utils/web3';

import './style.less';

const BearWidget = ({ id, onRemove }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const bear = null;
  if (!bear) return null;

  return (
    <div className="bear-widget">
      <div className="title">{shortenAddress(bear.owner)}</div>
      <div className="image-wrapper">
        <Image className="bear-photo" src={bear.photo} onClick={() => setOpenDetails(true)} />
        {onRemove && <Image className="remove-icon" src={icons.crossCircle} onClick={onRemove} />}
      </div>
      <div className="color-bar">
        {bear.geneticAttributes.map((attr, index) => (
          <div key={index} className="circle" style={{ backgroundColor: attr.color }} />
        ))}
      </div>
      {openDetails && (
        <Modal open className="bear-details-modal" onClose={() => setOpenDetails(false)}>
          <Modal.Content>
            <div className="close-icon" onClick={() => setOpenDetails(false)}>
              ✕
            </div>
            <BearDetails bear={bear} />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

BearWidget.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  onRemove: PropTypes.func,
};

export default BearWidget;
