import { createReducer } from 'reduxsauce';
import { Types } from 'redux/actions/bear';
import { success } from 'redux/utils/action';
import { ADMIN_SEARCH_WALLETS_FILTER } from 'utils/constants';

// Initial State
const initialState = {
  claimedAmount: 0,
  claimAmount: 0,
  dailyAmount: 0,
  properties: {},
  mintCount: 0,
  dropsMintCount: 0,
  bears: [],
  searchedBears: {},
  searchedBearsAdmin: {},
  bearEntires: 0,
  serumEntries: 0,
  spaceEntries: 0,
  myBears: [],
  mySerums: [],
  myCubs: [],
  similarBears: [],
  bearsByIds: [],
  bear: null,
  cub: null,
  multiplier: 1,
  superPair: false,
  bankAddress: '',
  bankBalance: 0,
  withdrawlSignature: '',
  depositFinished: false,
};

const resetMultiplier = (state, action) => ({
  ...state,
  multiplier: 1,
});

const getProperties = (state, action) => ({
  ...state,
  properties: action.payload,
});

const getMintCount = (state, action) => ({
  ...state,
  mintCount: action.payload.count,
});

const getSimilarBears = (state, action) => ({
  ...state,
  similarBears: action.payload,
});

const getBears = (state, action) => ({
  ...state,
  bears: action.payload,
});

const getBearsBySearch = (state, action) => ({
  ...state,
  searchedBears: action.payload,
});

const getBearsByOwner = (state, action) => ({
  ...state,
  myBears: action.payload,
});

const getBearsByIds = (state, action) => ({
  ...state,
  bearsByIds: action.payload,
});

const getBearById = (state, action) => ({
  ...state,
  bear: action.payload,
});

const getCubById = (state, action) => ({
  ...state,
  cub: action.payload,
});

const getSerumsByOwner = (state, action) => ({
  ...state,
  mySerums: action.payload,
});

const getSerumMintCount = (state, action) => ({
  ...state,
  dropsMintCount: action.payload.count,
});

const getSpace = (state, action) => ({
  ...state,
  spaceEntries: action.payload.spacePoint,
  bearEntries: action.payload.bearPoint,
  serumEntries: action.payload.serumPoint,
});

const getBankAddress = (state, action) => ({
  ...state,
  bankAddress: action.payload,
});

const deposit = (state, action) => ({
  ...state,
  depositFinished: action.payload.success,
});

const getBalance = (state, action) => ({
  ...state,
  bankBalance: action.payload.balance,
});

const withdraw = (state, action) => ({
  ...state,
  withdrawlSignature: action.payload,
});

const calcYieldlyReward = (state, action) => ({
  ...state,
  claimAmount: action.payload.claimAmount,
  dailyAmount: action.payload.dailyAmount,
});

const claimYieldlyReward = (state, action) => ({
  ...state,
  claimedAmount: action.payload.claimAmount,
});

const getCubsByOwner = (state, action) => ({
  ...state,
  myCubs: action.payload,
});

const getMultiplier = (state, action) => ({
  ...state,
  multiplier: action.payload.multiplier,
  superPair: action.payload.superPair,
});

// map action types to reducer functions
export const handlers = {
  [success(Types.GET_PROPERTIES)]: getProperties,
  [success(Types.GET_MINT_COUNT)]: getMintCount,
  [success(Types.GET_SIMILAR_BEARS)]: getSimilarBears,
  [success(Types.GET_BEARS)]: getBears,
  [success(Types.GET_BEARS_BY_SEARCH)]: getBearsBySearch,
  [success(Types.GET_BEARS_BY_OWNER)]: getBearsByOwner,
  [success(Types.GET_BEARS_BY_IDS)]: getBearsByIds,
  [success(Types.GET_BEAR_BY_ID)]: getBearById,
  [success(Types.GET_CUB_BY_ID)]: getCubById,
  [success(Types.GET_SERUMS_BY_OWNER)]: getSerumsByOwner,
  [success(Types.GET_SERUM_MINT_COUNT)]: getSerumMintCount,
  [success(Types.GET_SPACE)]: getSpace,
  [success(Types.GET_BALANCE)]: getBalance,
  [success(Types.GET_BANK_ADDRESS)]: getBankAddress,
  [success(Types.DEPOSIT)]: deposit,
  [success(Types.WITHDRAW)]: withdraw,
  [success(Types.CALC_YIELDLY_REWARD)]: calcYieldlyReward,
  [success(Types.CLAIM_YIELDLY_REWARD)]: claimYieldlyReward,
  [success(Types.GET_CUBS_BY_OWNER)]: getCubsByOwner,
  [success(Types.GET_MULTIPLIER)]: getMultiplier,
  [success(Types.RESET_MULTIPLIER)]: resetMultiplier,
};

// Export Reducer
export default createReducer(initialState, handlers);
