import axios from 'axios';
import { loadData } from './storage';

const apiEndpoint = process.env.REACT_APP_BASE_API_URL;

export const callLambda = async ({ method, url, body = {}, queryStringParameters = {}, unauthed = false }) => {
  const accessToken = loadData('access_token');
  console.log(method);
  const headers = unauthed ? {} : { Authorization: accessToken };
  const { data } = await axios({
    url: `${apiEndpoint}/${url}`,
    method,
    headers,
    params: queryStringParameters,
    data: body,
  });
  return data;
};

export const fetchAllResult = async ({ url }) => {
  const result = [];
  let lastItemKey;

  do {
    const response = await callLambda({
      method: 'POST',
      url,
      body: {
        pageLimit: 100,
        ExclusiveStartKey: lastItemKey,
      },
    });

    result.push(...response.Items);
    lastItemKey = response.LastEvaluatedKey;
  } while (lastItemKey);

  return result;
};
