import { Types } from 'redux/actions/bear';
import { callLambda } from 'utils/lambda';

const apis = {};

apis[Types.GET_PROPERTIES] = () =>
  callLambda({
    method: 'get',
    url: 'nfts/getProperties',
    unauthed: true,
  });

apis[Types.GET_MINT_COUNT] = () =>
  callLambda({
    method: 'get',
    url: 'nfts/getMintCount',
    unauthed: true,
  });

apis[Types.GET_SERUM_MINT_COUNT] = () =>
  callLambda({
    method: 'get',
    url: 'bearlabs/getMintCount',
    unauthed: true,
  });

apis[Types.GET_SIMILAR_BEARS] = (action) =>
  callLambda({
    method: 'get',
    url: 'nfts/getSimilar',
    queryStringParameters: {
      size: action.size,
    },
    unauthed: true,
  });

apis[Types.GET_BEARS] = (action) =>
  callLambda({
    method: 'get',
    url: 'nfts',
    queryStringParameters: {
      start: action.start,
      size: action.size,
    },
    unauthed: true,
  });

apis[Types.GET_BEARS_BY_SEARCH] = (action) =>
  callLambda({
    method: 'post',
    url: 'nfts/search',
    body: {
      start: action.start,
      size: action.size,
      search: action.search,
      filter: action.filter,
      addresses: action.addresses,
    },
    unauthed: true,
  });

apis[Types.GET_BEARS_BY_OWNER] = (action) =>
  callLambda({
    method: 'get',
    url: 'nfts/getListByOwner',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_SERUMS_BY_OWNER] = (action) =>
  callLambda({
    method: 'get',
    url: 'bearlabs/getListByOwner',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_BEARS_BY_IDS] = (action) =>
  callLambda({
    method: 'post',
    url: 'nfts/getListByIds',
    body: {
      ids: action.ids,
    },
    unauthed: true,
  });

apis[Types.GET_BEAR_BY_ID] = (action) =>
  callLambda({
    method: 'get',
    url: `nft/${action.id}`,
    unauthed: true,
  });

apis[Types.GET_CUB_BY_ID] = (action) =>
  callLambda({
    method: 'get',
    url: `badcub/${action.id}`,
    unauthed: true,
  });

apis[Types.GET_SPACE] = (action) =>
  callLambda({
    method: 'get',
    url: 'nfts/getSpace',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_BALANCE] = (action) =>
  callLambda({
    method: 'get',
    url: 'bank/getBalance',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_BANK_ADDRESS] = (action) =>
  callLambda({
    method: 'get',
    url: 'bank/getBankAddress',
    unauthed: true,
  });

apis[Types.DEPOSIT] = (action) =>
  callLambda({
    method: 'get',
    url: 'bank/deposit',
    queryStringParameters: {
      txHash: action.txHash,
    },
    unauthed: true,
  });

apis[Types.WITHDRAW] = (action) =>
  callLambda({
    method: 'get',
    url: 'bank/withdraw',
    queryStringParameters: {
      address: action.address,
      amount: action.amount,
    },
    unauthed: true,
  });

apis[Types.CALC_YIELDLY_REWARD] = (action) =>
  callLambda({
    method: 'get',
    url: 'staking/calcYieldlyReward',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.CLAIM_YIELDLY_REWARD] = (action) =>
  callLambda({
    method: 'get',
    url: 'staking/claimYieldlyReward',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_CUBS_BY_OWNER] = (action) =>
  callLambda({
    method: 'get',
    url: 'badcubs/getListByOwner',
    queryStringParameters: {
      address: action.address,
    },
    unauthed: true,
  });

apis[Types.GET_MULTIPLIER] = (action) =>
  callLambda({
    method: 'get',
    url: 'stakings/getMultiplier',
    queryStringParameters: {
      bearId: action.bearId,
      cubId: action.cubId,
    },
    unauthed: true,
  });

apis[Types.RESET_MULTIPLIER] = (action) => 1;
export default apis;
