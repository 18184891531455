import React, { useEffect, useState } from 'react';
import { Button, Icon, Image, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import _ from 'lodash';

import icons from 'assets/icons';
import { injected, walletconnect, walletlink, trezor } from 'connectors/Connector';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { Creators } from 'redux/actions/global';

import './style.less';

const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    icon: icons.metamaskIcon,
    description: 'Easy-to-use browser extension.',
    mobile: true,
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    icon: icons.walletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    icon: icons.coinbaseIcon,
    description: 'Use Coinbase Wallet app on mobile device',
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    icon: icons.coinbaseIcon,
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    mobile: true,
    mobileOnly: true,
  },
  TREZOR: {
    connector: trezor,
    name: 'Trezor',
    icon: icons.trezorIcon,
    description: 'Trezor wallet',
  },
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
  }
  if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  }
  if (error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect) {
    return 'Please authorize this website to access your Ethereum account.';
  }

  console.error(error);
  return 'An unknown error occurred. Check the console for more details.';
}

const WalletModal = ({ setCurrentDialog }) => {
  const { connector, activate, deactivate, active, error } = useWeb3React();
  const isMobile = useCheckMobileScreen();

  const [activatingConnector, setActivatingConnector] = useState();
  const [pendingWallet, setPendingWallet] = useState(undefined);
  const [supportedWallets, setSupportedWallets] = useState({});

  const tryActivation = async (_connector) => {
    const name = '';
    Object.keys(supportedWallets).map((key) => {
      if (_connector === supportedWallets[key].connector) {
        return name === supportedWallets[key].name;
      }
      return true;
    });

    setPendingWallet(connector);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (_connector instanceof WalletConnectConnector) {
      _connector.walletConnectProvider = undefined;
    }

    if (!_connector) return;

    activate(_connector, undefined, true)
      .then(async () => {
        const walletAddress = await _connector.getAccount();
        console.log('wallet address ', walletAddress);
        setPendingWallet(undefined);
      })
      .catch((err) => {
        if (err instanceof UnsupportedChainIdError) {
          activate(_connector); // a little janky...can't use setError because the connector isn't set
        } else {
          console.log('error ', err);
        }
      });
  };

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // log the walletconnect URI
  useEffect(() => {
    const filteredWallets = isMobile
      ? _.pickBy(SUPPORTED_WALLETS, (value, key) => value.mobile)
      : _.pickBy(SUPPORTED_WALLETS, (value, key) => !value.mobileOnly);
    setSupportedWallets(filteredWallets);

    const logURI = (uri) => {
      console.log('WalletConnect URI', uri);
    };
    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);

  useEffect(() => {
    if (active && activatingConnector && !pendingWallet) {
      setCurrentDialog(null);
    }
  }, [active]);

  return (
    <Modal className="wallet-modal" open onClose={() => setCurrentDialog(null)}>
      <Modal.Header>
        <div className="close-icon" onClick={() => setCurrentDialog(null)}>
          ✕
        </div>
      </Modal.Header>
      <Modal.Content>
        {Object.keys(supportedWallets).map((key) => {
          const currentConnector = supportedWallets[key].connector;
          const activating = currentConnector === activatingConnector;
          const connected = active && currentConnector === connector;
          const disabled = !!activating || connected;

          return (
            <Button
              key={key}
              disabled={disabled && !!supportedWallets[key].connector}
              loading={activating && !!supportedWallets[key].connector}
              onClick={() => {
                setActivatingConnector(currentConnector);
                if (supportedWallets[key].connector) {
                  activate(supportedWallets[key].connector);
                  if (active) {
                    setPendingWallet(supportedWallets[key].connector);
                  }
                } else {
                  window.open(supportedWallets[key].href, '_blank');
                }
              }}
            >
              <div>
                {connected && <Icon name="circle" color="green" size="small" />}
                <span>{supportedWallets[key].name}</span>
              </div>
              <Image className="wallet-logo" src={supportedWallets[key].icon} />
            </Button>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        {!!error && <span className="error-msg">{getErrorMessage(error)}</span>}
        <div className="button-wrapper">
          {pendingWallet && (
            <Button
              onClick={() => {
                tryActivation(pendingWallet);
              }}
            >
              Try again
            </Button>
          )}
          {(active || error) && (
            <Button
              className="disconnect"
              onClick={() => {
                deactivate();
              }}
            >
              Disconnect
            </Button>
          )}
        </div>
      </Modal.Actions>
    </Modal>
  );
};

WalletModal.propTypes = {
  setCurrentDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  ...Creators,
};

export default connect(null, mapDispatchToProps)(WalletModal);
