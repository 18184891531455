import React from 'react';
import { Image } from 'semantic-ui-react';

import icons from 'assets/icons';
import images from 'assets/images';

import './style.less';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="overlay">
      <Image className="spinner" src={images.logoMSKGif} />
    </div>
  </div>
);

export default LoadingSpinner;
