import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';

import images from 'assets/images';
import urls from 'routes/urls';
import { getFancyName } from 'utils/utility';

import './style.less';

const BearCard = ({
  bear,
  preview = true,
  enableLink = true,
  width = 20,
  onPair,
  pairingEnabled = false,
  isCub = false,
  bearId,
  cubId,
  isBee = false,
}) => {
  // const { chainId, account, library } = useWeb3React();

  const [superPair, setSuperPair] = useState(false);
  const [multiplier, setMultiplier] = useState(1);

  const getBearPairCub = async (cubToken, bearToken) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/stakings/getMultiplier`, {
        params: {
          bearId: bearToken,
          cubId: cubToken,
        },
      })
      .then((response) => {
        setMultiplier(response.data.multiplier);
        setSuperPair(response.data.superPair);
      })
      .catch((err) => {
        console.log(`error: ${err}`);
      });
  };

  useEffect(() => {
    if (pairingEnabled) {
      getBearPairCub(cubId, bearId);
    } else {
      if (isCub && bear.pairBear) {
        getBearPairCub(bear.tokenId, bear.pairBear);
      }
      if (!isCub && bear.pairCub) {
        getBearPairCub(bear.pairCub, bear.tokenId);
      }
    }
  }, []);

  return (
    <>
      <div className="bear-card" style={{ pointerEvents: enableLink ? '' : '', width: `${width}%` }}>
        {!pairingEnabled && !isBee && (
          <Link to={!isCub ? `${urls.BEAR}/${bear.tokenId}` : `${urls.CUB}/${bear.tokenId}`}>
            <div className="image-wrapper">
              <LazyLoadImage
                alt={`badbear #${bear.tokenId}`}
                src={preview ? bear.preview : bear.data?.image}
                placeholderSrc={images.preRevealGif}
                effect="blur"
              />
              <div className="overlay">
                <span>
                  {getFancyName(bear.data?.name)} #{bear.tokenId}
                </span>
                {multiplier > 1 && !superPair && (
                  <div className="special-trait-container">
                    <span className="special-trait">🧡 BEAR PAIR {100 * multiplier}MSK/Day</span>
                  </div>
                )}
                {multiplier > 1 && superPair && (
                  <div className="special-trait-container-super">
                    <span className="special-trait-super">🤍 SUPER BEAR PAIR {100 * multiplier}MSK/Day</span>
                  </div>
                )}
                {multiplier === 1 && (
                  <div className="trait-container">
                    <span className="trait">No Bear Pair</span>
                  </div>
                )}
              </div>
            </div>
          </Link>
        )}
        {isBee && (
          <div className="image-wrapper">
            <LazyLoadImage
              alt={`Bee Fren #${bear.edition}`}
              src={bear.image}
              placeholderSrc={images.preRevealGif}
              effect="blur"
            />
            <div className="overlay">
              <span>{bear.name}</span>
            </div>
          </div>
        )}
        {pairingEnabled && isCub && (
          <div className="image-wrapper" onClick={() => onPair(bear.tokenId)}>
            <LazyLoadImage
              alt={`badbear #${bear.tokenId}`}
              src={preview ? bear.preview : bear.data?.image}
              placeholderSrc={images.serum_animation_300_1}
              effect="blur"
            />
            {/* get multipler in here */}
            <div className="overlay">
              <span>
                {getFancyName(bear.data?.name)} #{bear.tokenId}
              </span>
              {multiplier > 1 && !superPair && (
                <div className="special-trait-container">
                  <span className="special-trait">🧡 BEAR PAIR {100 * multiplier}MSK/Day</span>
                </div>
              )}
              {multiplier > 1 && superPair && (
                <div className="special-trait-container-super">
                  <span className="special-trait-super">🤍 SUPER BEAR PAIR {100 * multiplier}MSK/Day</span>
                </div>
              )}
              {multiplier === 1 && (
                <div className="trait-container">
                  <span className="trait">No Bear Pair</span>
                </div>
              )}
            </div>
          </div>
        )}
        {pairingEnabled && !isCub && (
          <div className="image-wrapper" onClick={() => onPair(bear.tokenId)}>
            <LazyLoadImage
              alt={`badbear #${bear.tokenId}`}
              src={preview ? bear.preview : bear.data?.image}
              placeholderSrc={images.serum_animation_300_1}
              effect="blur"
            />
            <div className="overlay">
              <span>
                {getFancyName(bear.data?.name)} #{bear.tokenId}
              </span>
              {multiplier > 1 && !superPair && (
                <div className="special-trait-container">
                  <span className="special-trait">🧡 BEAR PAIR {100 * multiplier} MSK/Day</span>
                </div>
              )}
              {multiplier > 1 && superPair && (
                <div className="special-trait-container-super">
                  <span className="special-trait-super">🤍 SUPER BEAR PAIR {100 * multiplier} MSK/Day</span>
                </div>
              )}
              {multiplier === 1 && (
                <div className="trait-container">
                  <span className="trait">No Bear Pair</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

BearCard.propTypes = {
  bear: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  enableLink: PropTypes.bool,
  width: PropTypes.number,
};

export default BearCard;
