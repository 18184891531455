import { SupportedChainId } from 'utils/constants';

export const MSK_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_MSK_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_MSK_ADDRESS_RINKEBY,
};

export const MSK_WITHDRAW_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_MSK_WITHDRAW_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_MSK_WITHDRAW_ADDRESS_RINKEBY,
};

export const REWARDS_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_REWARDS_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_REWARDS_ADDRESS_RINKEBY,
};

export const BEAR_LABS_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_BEAR_LABS_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_BEAR_LABS_ADDRESS_RINKEBY,
};

export const BEAR_LABS_PROXY_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_BEAR_LABS_PROXY_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_BEAR_LABS_PROX_ADDRESS_RINKEBY,
};

export const NFT_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_NFT_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_NFT_ADDRESS_RINKEBY,
};

export const ETHER_SCAN_TX_PREFIX = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_ETHER_SCAN_TX_PREFIX_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_ETHER_SCAN_TX_PREFIX_RINKEBY,
};

export const INFURA_ADDRESS = {
  [SupportedChainId.MAINNET]: process.env.REACT_APP_INFURA_ADDRESS_MAINNET,
  [SupportedChainId.RINKEBY]: process.env.REACT_APP_INFURA_ADDRESS_RINKEBY,
};

export const operatorAddress = '';
