import React from 'react';

import './style.less';

const Loading = () => (
  <div className="loading-big-spinner">
    <svg className="loading__svg-container" height="100" width="100" viewBox="0 0 100 100">
      <circle className="loading__svg bg" cx="50" cy="50" r="45"></circle>
      <circle className="loading__svg animate" cx="50" cy="50" r="45"></circle>
    </svg>
  </div>
);

export default Loading;
