import background from './background.jpeg';
import bear1 from './bears/bear1.png';
import bear2 from './bears/bear2.png';
import bear3 from './bears/bear3.png';
import bear4 from './bears/bear4.png';
import bear5 from './bears/bear5.png';
import bear6 from './bears/bear6.png';
import bear7 from './bears/bear7.png';
import bear8 from './bears/bear8.png';
import bear9 from './bears/bear9.png';
import bear10 from './bears/bear10.png';
import bear11 from './bears/bear11.png';
import bear12 from './bears/bear12.png';
import bear13 from './bears/bear13.png';
import bear14 from './bears/bear14.png';
import bear15 from './bears/bear15.png';
import bear16 from './bears/bear16.png';
import bear17 from './bears/bear17.jpg';
import bear18 from './bears/bear18.png';
import bearUnknown from './bear-unknown.png';
import getInfo1 from './get-info/get-info-1.png';
import getInfo2 from './get-info/get-info-2.png';
import getInfo3 from './get-info/get-info-3.png';
import getInfo4 from './get-info/get-info-4.png';
import getInfo5 from './get-info/get-info-5.png';
import getInfo6 from './get-info/get-info-6.png';
import introGif from './intro.gif';
import logo from './logo.png';
import logoGif from './logo.gif';
import logoEther from './logo-ether.png';
import logoFull from './logo-full.png';
import logoMSKGif from './logo-msk.gif';
import logoMSKPng from './logo-msk.png';
import logoMSKSvg from './logo-msk.svg';
import preRevealGif from './pre-reveal.gif';
import cubTableUpdate from './cub_table_updated.png';
import newLogo from './newlogo.svg';
import serum_animation_300_1 from './serum_animation_300_1.gif';
import new_serum from './new_serum.gif';
import christmas_serum from './christmas_serum.gif';

const images = {
  background,
  bear1,
  bear2,
  bear3,
  bear4,
  bear5,
  bear6,
  bear7,
  bear8,
  bear9,
  bear10,
  bear11,
  bear12,
  bear13,
  bear14,
  bear15,
  bear16,
  bear17,
  bear18,
  bearUnknown,
  getInfo1,
  getInfo2,
  getInfo3,
  getInfo4,
  getInfo5,
  getInfo6,
  introGif,
  logo,
  logoGif,
  logoEther,
  logoFull,
  logoMSKGif,
  logoMSKPng,
  logoMSKSvg,
  preRevealGif,
  cubTableUpdate,
  newLogo,
  serum_animation_300_1,
  new_serum,
  christmas_serum,
};

export default images;
