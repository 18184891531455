import { createReducer } from 'reduxsauce';
import { cloneDeep } from 'lodash';

import { Types } from '../actions/global';

// Initial State
const initialState = {
  status: {},
  currentDialog: null,
};

/* Handlers */
const updateState = (state, action) => ({
  ...state,
  ...action.payload,
});

const setCurrentDialog = (state, action) => {
  const newState = cloneDeep(state);
  newState.currentDialog = {
    name: action.name,
    content: action.content,
  };

  return newState;
};

// map action types to reducer functions
export const handlers = {
  [Types.UPDATE_STATE]: updateState,
  [Types.SET_CURRENT_DIALOG]: setCurrentDialog,
};

// Export Reducer
export default createReducer(initialState, handlers);
