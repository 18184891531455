import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'components';
import { DIALOG_NAME } from 'utils/constants';

const WalletModal = lazy(() => import('components/WalletModal'));

const DialogWrapper = ({ currentDialog }) => {
  // No open dialog
  if (!currentDialog) return null;

  // Render
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {currentDialog.name === DIALOG_NAME.WalletModal && <WalletModal />}
    </Suspense>
  );
};

DialogWrapper.propTypes = {
  currentDialog: PropTypes.object,
};

export default DialogWrapper;
