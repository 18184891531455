import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button } from 'semantic-ui-react';

import images from 'assets/images';
import urls from 'routes/urls';
import { getFancyName } from 'utils/utility';

import './style.less';

const SerumCard = ({ serum, preview = true, enableLink = true, width = 20, revealCub }) => {
  console.log(serum);
  let image_url;
  if (serum.tokenId >= 4176 && serum.tokenId <= 4343) {
    image_url = images.christmas_serum;
  } else if (serum.tokenId >= 4161 && serum.tokenId <= 4175) {
    image_url = images.new_serum;
  } else {
    image_url = serum.image || images.serum_animation_300_1;
  }
  return (
    <div className="bear-card" style={{ pointerEvents: enableLink ? '' : 'none', width: `${width}%` }}>
      {/* Remove or add onClick={() => revealCub(serum.tokenId)} to toggle reveal function */}
      <div onClick={() => revealCub(serum.tokenId)} className="image-wrapper">
        <LazyLoadImage
          alt={`badbear #${serum.name}`}
          src={image_url}
          placeholderSrc={images.serum_animation_300_1}
          effect="blur"
        />
        <div className="overlay">
          <span>{serum.name}</span>
          <div>👆 Tap to use</div>
        </div>
      </div>
    </div>
  );
};

SerumCard.propTypes = {
  serum: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  enableLink: PropTypes.bool,
  width: PropTypes.number,
  revealCub: PropTypes.func.isRequired,
};

export default SerumCard;
