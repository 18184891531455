import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

import './style.less';

const BrandButton = ({ className, ...props }) => (
  <Button className={classNames('brand-button', className)} {...props} />
);

BrandButton.propTypes = {
  className: PropTypes.string,
};

export default BrandButton;
