import React from 'react';
import PropTypes from 'prop-types';
import { useWeb3React } from '@web3-react/core';
import { getFancyName } from 'utils/utility';

import './style.less';

const BearDetails = ({ bear }) => {
  const { chainId, account, library } = useWeb3React();

  return (
    <>
      <div className="bear-details">
        <div>
          <h2 className="name">{getFancyName(bear.data?.name)}</h2>
        </div>
        <div>
          <span className="description">{bear.data?.description}</span>
        </div>
        {bear.data?.attributes.map((att, index) => (
          <div key={index}>
            <span className="label">{att.trait_type}:</span>
            <span className="value">{att.value}</span>
          </div>
        ))}
        <div>
          <span className="label">$MSK:</span>
          <span className="value">100 Per Day</span>
        </div>
        <div>
          <span className="label">Owner:</span>
          <span className="value">
            <a href={`https://opensea.io/${bear.owner}`} target="_blank" rel="noreferrer">
              View owner on OpenSea
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

BearDetails.propTypes = {
  bear: PropTypes.object.isRequired,
};

export default BearDetails;
