import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    deposit: ['txHash'],
    getProperties: [],
    getMintCount: [],
    getSimilarBears: ['size'],
    getBalance: ['address'],
    getBankAddress: [],
    getBears: ['start', 'size'],
    getBearsBySearch: ['start', 'size', 'search', 'filter', 'addresses'],
    getBearsByOwner: ['address'],
    getBearsByIds: ['ids'],
    getBearById: ['id'],
    getCubById: ['id'],
    getSerumsByOwner: ['address'],
    getSerumMintCount: [''],
    getCubsByOwner: ['address'],
    getSpace: ['address'],
    withdraw: ['address', 'amount'],
    calcYieldlyReward: ['address'],
    claimYieldlyReward: ['address'],
    getMultiplier: ['bearId', 'cubId'],
    resetMultiplier: [],
  },
  { prefix: 'bear_' },
);

export { Types, Creators };
