export const saveData = async (key, value) => {
  if (value && key) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  }
};

export const loadData = async (key) => {
  try {
    const value = localStorage.getItem(key);

    return value;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const removeData = async (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};
