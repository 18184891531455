import React from 'react';
import { ConnectedRouter } from 'connected-react-router';

import Content from './Content';

function App({ history }) {
  return (
    <ConnectedRouter history={history}>
      <Content />
    </ConnectedRouter>
  );
}

export default App;
