export const SupportedChainId = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
};

export const ACTION_STATUS = {
  REQUEST: 'request',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const MINTING_STATUS = {
  INIT: 'init',
  WAITING: 'waiting',
  COMPLETED: 'completed',
  PENDING: 'pending',
};

export const APPROVE_STATUS = {
  INIT: 'init',
  WAITING: 'waitingapproval',
  COMPLETED: 'completedapproval',
  PENDING: 'approving',
};

export const CLAIM_STATUS = {
  INIT: 'init',
  WAITING: 'waitingclaim',
  COMPLETED: 'completedclaim',
  PENDING: 'claiming',
};

export const DEPOSIT_STATUS = {
  INIT: 'init',
  WAITING: 'waitingdeposit',
  COMPLETED: 'completeddepost',
  PENDING: 'depositing',
};

export const GEN_TYPE = {
  BEAR: 'BEAR',
  CUB: 'CUB',
};

export const CLAIM_TYPE = {
  BEARS: 'BEAR',
  SERUMS: 'SERUMS',
};

export const DIALOG_NAME = {
  WalletModal: 'WalletModal',
};

export const ADMIN_WALLETS = [
  '0x0f34d9C4E01f9f350536780fd1fBc05Aa3E3403D',
  '0x34D540E50a4DA79CcE7138DB8b66eA57Cdf49391',
  '0x32979E91cC09022DC32787BBc043d082fE1B51A2',
  '0x3Cbb2dC0905D2E4c03F1e8B22E898EFc92F78Af0',
  '0xF9d3d8376Ad581e0804AD451800a2Df8210b8a20',
  '0x272227B8f7B8449BB38D1eDc6D3fB6b549FbcEC3',
  '0x3C986C0D883630BB983a90d3960FDD31B30bE8ec',
];

// export const ADMIN_SEARCH_WALLETS_FILTER = process.env.ADMIN_SEARCH_WALLET;

export const ADMIN_SEARCH_WALLETS_FILTER = [
  '0xAbB36Ce3dE327BdFa242219F2b38E044869b7BE3',
  '0x7a3b288162C5fB44C801936Bf05cB72E7dc85537',
  '0x74CB9741940E0446071091A4Be1228ec13D4A437',
  '0xa61e83C750616B67afb6580a0707369622534E04',
  '0x076BD88D56A7Cb1EAAa4A6E7E68D60558966C4Af',
  '0x64DF7270E72fFF8C900286630526B1920a933541',
  '0xaA9AE92eE2Ae39249287D2eB2917723fdce2aCAE',
  '0xf091962f25610567beb3f6BC2656e5Ba6A105D18',
  '0x5cC68e3d268654F5aA7f788Dd5055A2618D88C0C',
  '0x7fDa7A2D425d6B1bD6C169BB901E20DcC2B6b12d',
  '0x8AaE5909553a1553594B9862aa2D20B9C25C5089',
  '0x3F14a5b43F80d989d46589fba594603e436506b1',
  '0x3c2f139b6C6B7cEE2D78F3424dbac9058CE8d44B',
  '0x2Fe2C2F93721d5F6E657ca65B4B66F0cC6Ac2D97',
  '0x8707fA6878294698309a45E21831a2934d6D8AeA',
  '0xE92a62F25d4cfB25961671eC96A5E0b3E786d3Cd',
  '0x77024ACb11276fe3Ee6261E93EC9293a4c1168EE',
  '0x9652eD763f3aE65deE3F605555fafEe14b18BABe',
  '0x8f68361b160cEdba5A0a7e42fB60000F10D1745c',
  '0x7C797f77dc0510E12704D4A918b21AE11c7937fF',
  '0x8B70A9AC9607A2e439c59fDb9932D80029Fc8DdF',
  '0x8feC3424B8FA03ff602C7701562b1425F799fE16',
  '0x210eC567D8A5F9bDa6a9a8e0Ff6b642748650dd5',
  '0xe967634892109B37D89C16c06e963dEE87021B10',
  '0xD35e024C5007201632303038CD45C989D22682b6',
  '0xC9C02BF9d0E09Cb5A4Ef98aAFbEBA1DeBF5A7364',
  '0x28BB0390e35cDB644858AA9E348A408100D5245f',
  '0x06098023Cbd6D25b5C1a8FcC4B6Df2AA4518d22B',
  '0x53A1409E780e325D05AE11B1b5DB7e44064D8129',
  '0x48fec749d905041F48069a12730d1f7DB8F8B43F',
  '0x2e2b825d05F837D6133A47D90CD104F972CaFa06',
  '0xa40b76AFeaDCA7838BA9Cb7c40C0d4ac10b88C76',
  '0xfe0d60668C47ae637D22A97077955694083b7b48',
  '0x2Ba5dEFBCEa22bBe1dB1f4C5011d329749B8Dbdd',
  '0xbD9A3f0537f3688cc31d7956975fE8F22a79066A',
  '0x8cC305f799F33ba4a6630D133d592FF9B252e8f6',
  '0x5B385867f9652813CBe2Df8129DC62d4F2Ed7fD8',
  '0xcdB55879D86715855107F1Ef9945784beaDcF026',
  '0xbA155948D510bd07C89331906c7fe0aA72235880',
  '0x02569611fEa7392D0951f7b8208459D5BE9EB1A1',
  '0xdE29A2899Fe5202C29D74B15f20EFf44701F4268',
  '0xb426C6B5305b9CE7B39e76903e4f6F49FF219FF1',
  '0x25825c8019Dd178be432f40b0C9c967092DB9ced',
  '0xfe9dA25489f9707fb98Fa32515B0401332C29843',
  '0x9eFca46E080bEEC43498d629fbD10943621a8374',
  '0x2d0E891505F1C1029CEc3dDe018Ee94F0B4BB8b0',
  '0xdcdD43a55D2320557A8d8413b8b05bA400028759',
  '0x4DdB021d9Bde5BBa6dff0bF7df973e27BAAf40eE',
  '0xe6B9D681CbC9a5bBEb6ebBc92Ce962ED67e1fd20',
  '0x889790D4443F4CF3c91F7ed8BF809ABfd06DfdFa',
  '0x101C601FA26EA50f8524cDb71d74486988e4133F',
  '0x10ece40a9F193488F42f2f8150Aa53cD2F8827A0',
  '0x5289bbF5378513FDa42f85C439e86BD504837C37',
  '0xE87D1CbBD95C9515BD3e32e30B2A4b4C593aC88B',
  '0x9989829f6c72DA9eB7339A6e14317704Cb8C80A2',
  '0x72E5C9165E0B18Dd5239EA84b02eA2684eDE1CdE',
  '0xBc6BdBBd50f8FCD1B63665E8Dae9Fac91fe41628',
  '0x0B62f75834Db08E4BF5Acc734c3D0AA1DAdbfe44',
  '0xB9623586498F53C8396d30Cc1B293B4C51384876',
  '0xeB2c06eb3a343D76D6E2A98fFf7747c92167a71e',
  '0x82CcE96B0cD45376ed012b0584A472c378bC4F3D',
  '0x1493e61670C5A076CB6CA33abF6d15f24b9defC3',
  '0x1a4a056C66040C53B466D2061B665F6b8a11587b',
  '0x356ED202Ae399281b68141e493B1cf18a5AcBdf4',
  '0x054C76840654788F1b508A6431b91eCd32406d6B',
  '0x4ED918F1f56d8328eFc53eDD99500B0cb6850e6f',
  '0x27c7f2855813Cb637Bf400638218f7B299509089',
  '0xc67ef3f55457DC7941b4293750fd66241EcD6CAe',
  '0xAf1A9F635fF3b8BacedDDE3eb9Db3589f44dB4aB',
  '0xa6Cf01743356698C11923056aaD1fBa78805c554',
  '0x2CB5519208A4D828ab8b41c98844c5C9B027801d',
  '0x3b24ACADc4B0b94c4bb75d51f922DA204E7EA046',
  '0xBdeD3AdD12A3191490ac74569F54304053aAB2c6',
  '0x6b198149B3EA9205C4b9a5CA20D959BF9D5EB1Af',
  '0xa83a5000439Db26a621781D613E9CFCce38486E5',
  '0x0015A2045B9d834E9AdF54bFc5aC1b7f971092a1',
  '0x6cbF699E5721Ef603A9efFf63d2b1Fee38330D6A',
  '0xB36b4372ca276D3e35462eD86bC2459fA07b4a0A',
  '0x7FDb95525e9E48e3404d93F4FE2ede075547a0D7',
  '0x341ba7479dAEE7834967b88ac6ca380b1aa7d5F0',
  '0xee53c7a106Ec543765F9ea6c0c53b708AE96CA8A',
  '0x1E248Fbf4F7FBE559C2fBcd5786919cD39198fb8',
  '0xA191F6dCDA0037e6e76a2c43BbFb3E21d9D9c565',
  '0x3975Ba9d7B7C250CA284A31597c0A43c35a85f06',
  '0x75d2b008bEDD7A805BF91e22f1B5293F4cc79AeA',
  '0xc7c36Aa57524efE687e07b85616b60cD023b0A24',
  '0x624aCf53d5BCb84C71e5302be926c709c7bCc039',
  '0xC94f3763BBDa440A893CfD9b769FF33d8CddB575',
  '0x5C8237e411e38465da12E6dE3a7F3F58f99995e2',
  '0xd20D31cbB3f0bf4CcaA91874b370663BaF662ae3',
  '0x271C93bCeAd4cb34fa9eB7e05602d1152637baDa',
  '0x38C843b8dBC9AD6e2A047678bBF0F1d4d9a37Ba6',
];
