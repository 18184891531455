import React from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'semantic-ui-react';

import images from 'assets/images';
import { BrandButton, BearWidget } from 'components';

import './style.less';

const BreedConfirmModal = ({ sireBearId, spouseBearId, cost, onConfirm, onClose }) => (
  <Modal className="breed-confirm-modal" open onClose={onClose}>
    <Modal.Header>
      <span>Are you sure want to breed?</span>
      <div className="close-icon" onClick={onClose}>
        ✕
      </div>
    </Modal.Header>
    <Modal.Content>
      <div className="parents-bear-wrapper">
        <BearWidget id={sireBearId} />
        <span className="plus">+</span>
        <BearWidget id={spouseBearId} />
      </div>
      <div className="child-bear-wrapper">
        <div className="bear-unknown">
          <Image src={images.bearUnknown} />
        </div>
        <div className="color-bar">
          {[...Array(5).keys()].map((index) => (
            <div key={index} className="circle" />
          ))}
        </div>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <span>{cost} $MSK</span>
      <BrandButton onClick={onConfirm}>Confirm</BrandButton>
    </Modal.Actions>
  </Modal>
);

BreedConfirmModal.propTypes = {
  sireBearId: PropTypes.number.isRequired,
  spouseBearId: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BreedConfirmModal;
