import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoadingSpinner } from 'components';
import { ACTION_STATUS } from 'utils/constants';
import { Types as bearTypes } from 'redux/actions/bear';
import Routes from 'routes';

import DialogWrapper from './DialogWrapper';

const Content = ({ global: { status, currentDialog } }) => {
  const loadingTypes = [
    bearTypes.GET_MINT_COUNT,
    bearTypes.GET_BEAR_BY_ID,
    bearTypes.GET_BEARS,
    bearTypes.GET_BEARS_BY_OWNER,
  ];

  return (
    <>
      <Routes />

      <DialogWrapper currentDialog={currentDialog} />

      {loadingTypes.map((t) => status[t]).includes(ACTION_STATUS.REQUEST) && <LoadingSpinner />}
    </>
  );
};

const mapStateToProps = (store) => ({
  global: store.global,
});

Content.propTypes = {
  global: PropTypes.object,
};

export default connect(mapStateToProps)(Content);
