import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BrandButton } from 'components';
import { Creators } from 'redux/actions/global';
import { DIALOG_NAME } from 'utils/constants';

const ConnectButton = ({ setCurrentDialog }) => (
  <BrandButton onClick={() => setCurrentDialog(DIALOG_NAME.WalletModal)}>Connect Wallet</BrandButton>
);

const mapDispatchToProps = {
  ...Creators,
};

ConnectButton.propTypes = {
  setCurrentDialog: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ConnectButton);
