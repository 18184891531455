import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { BearCard } from 'components';

import './style.less';

const CubPairingModal = ({
  onClose,
  cubs,
  pairingEnabled,
  onPair,
  isBear = false,
  bearId,
  getMultiplier,
  multiplier,
}) => (
  <Modal className="minting-modal" closeOnDimmerClick={false} open onClose={onClose}>
    <Modal.Header>
      <div className="title-bar">
        <div className="text-wrapper">
          <p className="title">Bear Pair</p>
          <p className="sub-title">Select a {isBear ? 'bear' : 'cub'} to make a Bear Pair</p>
        </div>
      </div>
      <div className="close-icon" onClick={onClose}>
        ✕
      </div>
    </Modal.Header>
    <Modal.Content>
      {cubs.length > 0 && (
        <div className="bears-wrapper">
          {cubs.map((b, index) => (
            <BearCard
              enableLink={false}
              key={index}
              bear={b}
              onPair={onPair}
              pairingEnabled={pairingEnabled}
              isCub={!isBear}
              bearId={!isBear ? bearId : b.tokenId}
              cubId={!isBear ? b.tokenId : bearId}
              getMultiplier={getMultiplier}
              multiplier={multiplier}
            />
          ))}
        </div>
      )}
    </Modal.Content>
    <Modal.Actions>
      <div className="action-container">
        <a href={`https://opensea.io/collection/badbearsgenesis`} target="_blank" rel="noreferrer">
          <Button className="action">Buy on OpenSea</Button>
        </a>
      </div>
    </Modal.Actions>
  </Modal>
);

CubPairingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  cubs: PropTypes.array.isRequired,
};

export default CubPairingModal;
