import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { LoadingSpinner } from 'components';
import NotFound from 'pages/NotFound';
import urls from 'routes/urls';

const routeViews = [
  {
    exact: true,
    path: urls.MINT,
    component: lazy(() => import('pages/Mint')),
  },
  {
    exact: true,
    path: `${urls.BREED}/:id`,
    component: lazy(() => import('pages/Breed')),
  },
  {
    exact: true,
    path: `${urls.BEAR}/:id`,
    component: lazy(() => import('pages/BearDetail')),
  },
  {
    exact: true,
    path: `${urls.CUB}/:id`,
    component: lazy(() => import('pages/CubDetail')),
  },
  // {
  //   exact: true,
  //   path: urls.ALL_BEARS,
  //   component: lazy(() => import('pages/Bears')),
  // },
  {
    exact: true,
    path: urls.WALLET,
    component: lazy(() => import('pages/Wallet')),
  },
  {
    exact: true,
    path: urls.VAULT,
    component: lazy(() => import('pages/Vault')),
  },
  {
    exact: true,
    path: `${urls.VAULT}/:id`,
    component: lazy(() => import('pages/VaultDetail')),
  },
  // {
  //   exact: true,
  //   path: urls.GET_MSK,
  //   component: lazy(() => import('pages/Swap')),
  // },
  {
    exact: true,
    path: urls.ADMIN,
    component: lazy(() => import('pages/Admin')),
  },
  {
    exact: true,
    path: urls.ADMIN_FOUNDERS,
    component: lazy(() => import('pages/FounderAdmin')),
  },
  {
    exact: true,
    path: urls.CONNECT,
    component: lazy(() => import('pages/Connect')),
  },
];

const Routes = () => (
  <main>
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        {routeViews.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => <route.component {...props} />}
          />
        ))}
        <Redirect exact path="/" to={urls.WALLET} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </main>
);

export default Routes;
