import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Modal } from 'semantic-ui-react';

import images from 'assets/images';
import { LoadingBigSpinner } from 'components';
import { ETHER_SCAN_TX_PREFIX } from 'utils/config';
import { MINTING_STATUS, APPROVE_STATUS } from 'utils/constants';
import './style.less';

const MintingModal = ({ chainId, hash, status, onClose, helpText = false }) => (
  <Modal className="minting-modal" closeOnDimmerClick={false} open onClose={onClose}>
    <Modal.Header>
      <Image src={images.logoGif} />
      {status === APPROVE_STATUS.PENDING && <span>Approving...</span>}
      {status === MINTING_STATUS.PENDING && <span>Minting...</span>}
      {status === MINTING_STATUS.COMPLETED && (
        <>
          <span>Transaction Completed</span>
          <div className="close-icon" onClick={onClose}>
            ✕
          </div>
        </>
      )}
      <Modal.Content>{status === MINTING_STATUS.PENDING && <LoadingBigSpinner />}</Modal.Content>
      <Modal.Content>{status === APPROVE_STATUS.PENDING && <LoadingBigSpinner />}</Modal.Content>
      {helpText === true && (
        <>
          <div>
            Please wait for the approve MSK Spend transaction to complete. Once it is complete, you will get a new Mint
            Serum Transaction to approve. Please do not refresh or navigate away from the page until it is complete.
          </div>
        </>
      )}
    </Modal.Header>
    <Modal.Actions>
      <a href={`${ETHER_SCAN_TX_PREFIX[chainId]}${hash}`} target="_blank" rel="noreferrer">
        <Button>View tx on etherscan</Button>
      </a>
    </Modal.Actions>
  </Modal>
);

MintingModal.propTypes = {
  chainId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MintingModal;
