import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Image, Menu } from 'semantic-ui-react';
import classnames from 'classnames';
import { useWeb3React } from '@web3-react/core';
import { formatEther } from '@ethersproject/units';

import images from 'assets/images';
import { ConnectButton } from 'components';
import urls from 'routes/urls';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { shortenAddress } from 'utils/web3';
import { DIALOG_NAME } from 'utils/constants';
import { Creators } from 'redux/actions/global';

import './style.less';
import 'animate.css';

const AppHeader = ({ setCurrentDialog }) => {
  const { active, account, library } = useWeb3React();
  const [openMenu, setOpenMenu] = useState(false);
  const [ethBalance, setEthBalance] = useState();
  const history = useHistory();
  const isMobile = useCheckMobileScreen();
  const menus = [
    // {
    //   title: 'Home',
    //   path: urls.HOME,
    //   icon: null,
    // },
    // {
    //   title: '$MSK',
    //   path: urls.GET_MSK,
    //   icon: null,
    // },
    // {
    //   title: 'Bears',
    //   path: urls.ALL_BEARS,
    //   icon: null,
    // },
    {
      title: 'Profile',
      path: urls.WALLET,
      icon: null,
    },
    {
      title: 'Vault',
      path: urls.VAULT,
      icon: null,
    },
  ];

  useEffect(() => {
    let isMounted = true; // To fix memory leak issue
    setInterval(() => {
      if (!account || !library) return;
      library
        .getBalance(account)
        .then((balance) => {
          const _ethBalance = parseFloat(formatEther(balance)).toFixed(2);
          if (isMounted) {
            setEthBalance(_ethBalance);
          }
        })
        .catch(() => {
          if (isMounted) {
            setEthBalance(null);
          }
        });
    }, 2000);

    return () => {
      isMounted = false;
    };
  }, [account, library]);

  return (
    <div className="app-header">
      {isMobile && (
        <Menu fluid fixed="bottom" widths={2}>
          <Menu.Item
            name="Profile"
            link
            onClick={() => history.push(urls.WALLET)}
            active={!history.location.pathname.localeCompare(urls.WALLET)}
          />
          {/* <Menu.Item
            name="Swap"
            link
            onClick={() => history.push(urls.GET_MSK)}
            active={!history.location.pathname.localeCompare(urls.GET_MSK)}
          /> */}
          {/* <Menu.Item
            name="Bears"
            link
            onClick={() => history.push(urls.ALL_BEARS)}
            active={!history.location.pathname.localeCompare(urls.ALL_BEARS)}
          /> */}
          <Menu.Item
            name="Vault"
            link
            onClick={() => history.push(urls.VAULT)}
            active={!history.location.pathname.localeCompare(urls.VAULT)}
          />
        </Menu>
      )}
      <div className="logo-menu-wrapper">
        <Image className="logo" src={images.newLogo} onClick={() => history.push(urls.WALLET)} />
        <div className={classnames('nav-item-list', { isMobile })}>
          {(!isMobile || (isMobile && openMenu)) &&
            menus.map(({ title, path, icon }) => (
              <Link
                key={path}
                to={path}
                className={classnames({ active: !history.location.pathname.localeCompare(path) })}
                onClick={() => setOpenMenu(false)}
              >
                <div>
                  {icon && <Image src={icon} />}
                  {title}
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="button-wrapper">
        {/* <Button
          className="mint-btn"
          onClick={() => window.open('https://opensea.io/collection/badbearsgenesis', '_blank')}
        >
          OpenSea
        </Button> */}
        {active ? (
          <div className="account-info-wrapper">
            <span>{ethBalance} ETH</span>
            <div className="address-wrapper" onClick={() => setCurrentDialog(DIALOG_NAME.WalletModal)}>
              <b>{shortenAddress(account, isMobile ? 2 : 4)}</b>
            </div>
          </div>
        ) : (
          <ConnectButton />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  ...Creators,
};

AppHeader.propTypes = {
  setCurrentDialog: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AppHeader);
