import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    updateState: ['payload'],
    setCurrentDialog: ['name', 'content'],
  },
  { prefix: 'global_' },
);

export { Types, Creators };
