import { put, call, takeLatest, all, select } from 'redux-saga/effects';

import { requestCreator, successCreator, failureCreator } from 'redux/utils/action';
import { Types } from 'redux/actions/bear';
import API from 'redux/api/bear';

function* sagaAction(action) {
  const { type } = action;
  try {
    yield put(requestCreator(type, {}));
    const payload = yield call(API[type], action);
    yield put(successCreator(type, payload));
  } catch (err) {
    console.error(err);
    yield put(failureCreator(type, { err }));
  }
}

export function* bearSaga() {
  yield all([takeLatest(Types.GET_PROPERTIES, sagaAction)]);
  yield all([takeLatest(Types.GET_MINT_COUNT, sagaAction)]);
  yield all([takeLatest(Types.GET_BEARS, sagaAction)]);
  yield all([takeLatest(Types.GET_BEARS_BY_SEARCH, sagaAction)]);
  yield all([takeLatest(Types.GET_SIMILAR_BEARS, sagaAction)]);
  yield all([takeLatest(Types.GET_BEARS_BY_OWNER, sagaAction)]);
  yield all([takeLatest(Types.GET_BEARS_BY_IDS, sagaAction)]);
  yield all([takeLatest(Types.GET_BEAR_BY_ID, sagaAction)]);
  yield all([takeLatest(Types.GET_CUB_BY_ID, sagaAction)]);
  yield all([takeLatest(Types.GET_SERUMS_BY_OWNER, sagaAction)]);
  yield all([takeLatest(Types.GET_SERUM_MINT_COUNT, sagaAction)]);
  yield all([takeLatest(Types.GET_SPACE, sagaAction)]);
  yield all([takeLatest(Types.GET_BALANCE, sagaAction)]);
  yield all([takeLatest(Types.GET_BANK_ADDRESS, sagaAction)]);
  yield all([takeLatest(Types.DEPOSIT, sagaAction)]);
  yield all([takeLatest(Types.WITHDRAW, sagaAction)]);
  yield all([takeLatest(Types.CALC_YIELDLY_REWARD, sagaAction)]);
  yield all([takeLatest(Types.CLAIM_YIELDLY_REWARD, sagaAction)]);
  yield all([takeLatest(Types.GET_CUBS_BY_OWNER, sagaAction)]);
  yield all([takeLatest(Types.GET_MULTIPLIER, sagaAction)]);
  yield all([takeLatest(Types.RESET_MULTIPLIER, sagaAction)]);
}
