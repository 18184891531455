import berry from './berry.png';
import coinbaseIcon from './coinbase-icon.svg';
import crossCircle from './cross-circle.png';
import discordLogo from './discord-logo-white.svg';
import etherscanLogo from './etherscan-logo-white.svg';
import handBack from './hand-back.svg';
import handNext from './hand-next.svg';
import metamaskIcon from './metamask-icon.png';
import beeIcon from './bee.png';
import question from './question.svg';
import spinner from './spinner.gif';
import trezorIcon from './trezor-icon.png';
import twitterLogo from './twitter-logo-white.svg';
import walletConnectIcon from './wallet-connect-icon.svg';
import yellowStar from './yellow-star.svg';
import hunny from './hunny.svg';

const icons = {
  berry,
  coinbaseIcon,
  crossCircle,
  discordLogo,
  etherscanLogo,
  handBack,
  handNext,
  metamaskIcon,
  question,
  spinner,
  trezorIcon,
  twitterLogo,
  walletConnectIcon,
  yellowStar,
  hunny,
  beeIcon,
};

export default icons;
