import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import './style.less';

const GetInfo = ({ image, text }) => (
  <div className="get-info">
    <Image src={image} />
    <div className="text-wrapper">
      <p>{text}</p>
    </div>
  </div>
);

GetInfo.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default GetInfo;
