import React from 'react';

import './style.less';

const NotFound = () => (
  <div className="not-found-page page">
    <p>Page Not Found</p>
  </div>
);

export default NotFound;
