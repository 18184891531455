import React from 'react';
import { Image } from 'semantic-ui-react';

import images from 'assets/images';

import './style.less';

const Footer = () => (
  <div className="footer">
    <div className="footer-wrapper">
      <div className="logo">
        <Image src={images.newLogo} />
      </div>
    </div>
  </div>
);

export default Footer;
