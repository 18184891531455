const urls = {
  ADMIN: '/admin',
  ADMIN_FOUNDERS: '/foundersAdmin',
  // ALL_BEARS: '/all-bears',
  CUB: '/cub',
  BEAR: '/bear',
  BREED: '/breed',
  // GET_MSK: '/swap',
  HOME: '/',
  MINT: '/mint',
  WALLET: '/profile',
  VAULT: '/vault',
  CONNECT: '/connect',
};

export default urls;
