import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import './style.less';

const TitleBar = ({ title, subTitle, imgSrc, account, owner, multiplier, superPair }) => (
  <div className="title-bar">
    <div className="overlay" />
    <div className="text-wrapper">
      {title && <p className="title">{title}</p>}
      {/* {title && account && owner && account.toLowerCase() !== owner.toLowerCase() && <p className="title">{title}</p>} */}
      {subTitle && <p className="sub-title">{subTitle}</p>}
      {multiplier > 1 && !superPair && (
        <div className="special-trait-container">
          <span className="special-trait">🧡 BEAR PAIR {multiplier}X</span>
        </div>
      )}
      {multiplier > 1 && superPair && (
        <div className="special-trait-container-super">
          <span className="special-trait-super">🤍 SUPER BEAR PAIR {multiplier}X</span>
        </div>
      )}
      {multiplier === 1 && (
        <div className="trait-container">
          <span className="trait">No Bear Pair</span>
        </div>
      )}
    </div>
    {imgSrc && <Image src={imgSrc} />}
  </div>
);

TitleBar.propTypes = {
  title: PropTypes.string || PropTypes.object,
  subTitle: PropTypes.string,
  imgSrc: PropTypes.string,
};

export default TitleBar;
