import axios from 'axios';
import fileDownload from 'js-file-download';

export const getFancyName = (name) => name.split('•')[1];

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(0)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num > 1000000) {
    return `${(num / 1000000).toFixed(0)}M`; // convert to M for number from > 1 million
  }
  return num;
};

export const handleDownload = (url, filename) => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};
